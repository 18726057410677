// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interplast-tsx": () => import("./../../../src/pages/interplast.tsx" /* webpackChunkName: "component---src-pages-interplast-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-non-surgical-dermal-fillers-tsx": () => import("./../../../src/pages/non-surgical/dermal-fillers.tsx" /* webpackChunkName: "component---src-pages-non-surgical-dermal-fillers-tsx" */),
  "component---src-pages-non-surgical-excess-underarm-sweating-tsx": () => import("./../../../src/pages/non-surgical/excess-underarm-sweating.tsx" /* webpackChunkName: "component---src-pages-non-surgical-excess-underarm-sweating-tsx" */),
  "component---src-pages-non-surgical-ipl-and-laser-treatments-tsx": () => import("./../../../src/pages/non-surgical/ipl-and-laser-treatments.tsx" /* webpackChunkName: "component---src-pages-non-surgical-ipl-and-laser-treatments-tsx" */),
  "component---src-pages-non-surgical-skin-treatments-tsx": () => import("./../../../src/pages/non-surgical/skin-treatments.tsx" /* webpackChunkName: "component---src-pages-non-surgical-skin-treatments-tsx" */),
  "component---src-pages-patient-information-tsx": () => import("./../../../src/pages/patient-information.tsx" /* webpackChunkName: "component---src-pages-patient-information-tsx" */),
  "component---src-pages-procedures-breast-augmentation-tsx": () => import("./../../../src/pages/procedures/breast-augmentation.tsx" /* webpackChunkName: "component---src-pages-procedures-breast-augmentation-tsx" */),
  "component---src-pages-procedures-breast-reconstruction-tsx": () => import("./../../../src/pages/procedures/breast-reconstruction.tsx" /* webpackChunkName: "component---src-pages-procedures-breast-reconstruction-tsx" */),
  "component---src-pages-procedures-breast-reduction-tsx": () => import("./../../../src/pages/procedures/breast-reduction.tsx" /* webpackChunkName: "component---src-pages-procedures-breast-reduction-tsx" */),
  "component---src-pages-procedures-earlobe-repair-tsx": () => import("./../../../src/pages/procedures/earlobe-repair.tsx" /* webpackChunkName: "component---src-pages-procedures-earlobe-repair-tsx" */),
  "component---src-pages-procedures-eyelid-surgery-tsx": () => import("./../../../src/pages/procedures/eyelid-surgery.tsx" /* webpackChunkName: "component---src-pages-procedures-eyelid-surgery-tsx" */),
  "component---src-pages-procedures-facelift-tsx": () => import("./../../../src/pages/procedures/facelift.tsx" /* webpackChunkName: "component---src-pages-procedures-facelift-tsx" */),
  "component---src-pages-procedures-mole-removal-tsx": () => import("./../../../src/pages/procedures/mole-removal.tsx" /* webpackChunkName: "component---src-pages-procedures-mole-removal-tsx" */),
  "component---src-pages-procedures-nose-surgery-tsx": () => import("./../../../src/pages/procedures/nose-surgery.tsx" /* webpackChunkName: "component---src-pages-procedures-nose-surgery-tsx" */),
  "component---src-pages-procedures-prominent-ear-correction-tsx": () => import("./../../../src/pages/procedures/prominent-ear-correction.tsx" /* webpackChunkName: "component---src-pages-procedures-prominent-ear-correction-tsx" */),
  "component---src-pages-procedures-scar-revision-tsx": () => import("./../../../src/pages/procedures/scar-revision.tsx" /* webpackChunkName: "component---src-pages-procedures-scar-revision-tsx" */),
  "component---src-pages-procedures-skin-cancer-surgery-tsx": () => import("./../../../src/pages/procedures/skin-cancer-surgery.tsx" /* webpackChunkName: "component---src-pages-procedures-skin-cancer-surgery-tsx" */),
  "component---src-pages-procedures-tummy-tuck-tsx": () => import("./../../../src/pages/procedures/tummy-tuck.tsx" /* webpackChunkName: "component---src-pages-procedures-tummy-tuck-tsx" */)
}

